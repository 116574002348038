import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';


import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';

import AccountCircle from '@mui/icons-material/AccountCircle';

import { reloadingData } from '../actions/userAction';

import { Link } from 'react-router-dom';
import { logoutUser } from "../actions/logoutUser";
import { connect } from "react-redux";
import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGridProcessedProps } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom'
import { Autocomplete, experimentalStyled, FormControl, Grid, TextField } from '@mui/material';
import { UserIcon } from './icon/userIcon';
import { StoreIcon } from './icon/storeIcon';
import { style } from '@mui/system';
import { selectedClient } from '../actions/clientSelectedAction';
import { getextranetUrl } from '../actions/loginUser';

function NavBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [locationKeys, setLocationKeys] = React.useState([])
  const history = useHistory()


  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [anchorsEl, setAnchorsEl] = React.useState(null);
  const [activelink, setActivelink] = React.useState(0);
  const matches = useMediaQuery('(min-width:980px)');
  const [currentLink, setCurrentLink] = React.useState(null);
  const [timer, setTimer] = React.useState(0)
  const [extranetUrl,setExtranetUrl]=React.useState("")
  const [dvUrl,setDvUrl]=React.useState("")
  const [trelloUrl,setTrelloUrl]=React.useState("")
  const [clients,setClients]=React.useState([])
  
  React.useEffect(() => {
    history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys)

          // Handle forward event

        } else {
          // setLocationKeys((keys) => [ location.key, ...keys ])
          setAnchorsEl(false);

          // Handle back event
        }
      }
    })
    const interval = setInterval(() => {
      setTimer(timer => timer + 1)

    }, 60000)


    switch (window.location.pathname.toLowerCase()) {
      case "/evenements":
        setActivelink(0)
        break;
      case "/evenementgrc":
        setActivelink(1)
        break;
      case "/actionclient":
        setActivelink(2)
        break;
      case "/appuifinance":
        setActivelink(3)
        break;
      case "/demandemonument":
        setActivelink(4)
        break;
    }
  }, []);

  async function checkTimer() {

    if (timer > 30) {

      await props.reloadData()
      setTimer(0);
    }
  }

  let open = Boolean(anchorsEl);
  const handleMnClick = (event) => {
    setAnchorsEl(event.currentTarget);
  };
  const handleMnClose = (e) => {
    checkTimer();
    const element = document.getElementsByClassName("menu")

    for (var i = 0; i < element.length; i++) {
      element[i].classList.remove("active");


    }

    if (!matches) {
      if (e.currentTarget.classList.value.includes("menu")) {
        setActivelink(parseInt(e.currentTarget.id))
      }
    }
    else {
      e.currentTarget.classList.add("active")
    }
    setTimeout(function () { setAnchorsEl(null) }, 500);

  };

  
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {

    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogOut = () => {
    props.logoutUser();
    handleMenuClose();
  }

  const menuId = 'primary-search-account-menu';



  React.useEffect(() => {
    if (props.isLoggedIn) {
    
      setExtranetUrl(props.user.extranet_url)
      setDvUrl("")
  
      if (localStorage.getItem('clientSelected')!==null){
        const clientSelected=JSON.parse(localStorage.getItem('clientSelected'));
        setExtranetUrl(props.user.extranet_url+"&id="+clientSelected.id)
        setDvUrl("?client_id="+clientSelected.id)
      }
      setTrelloUrl(props.user.trello_url)
      const client =  (props.user.params.clients.length > 0) ?
      props.user.params.clients.map(client => {
        return { label: client.nomClient + " - " + client.codeClient, id: client.codeClient }
      }) : [];
      setClients(client)
    }
  }, [props]);
 
    

  const redirectToExtranet = async () => {
    let id=null;
    if (localStorage.getItem('clientSelected')!==null){
      const clientSelected=JSON.parse(localStorage.getItem('clientSelected'));
      id=clientSelected.id
    }
    await props.getextranetUrl(id);
    
    setTimeout(function () { setAnchorsEl(null) }, 500);
  }
  
  const handleSelectedClient =(event, newValue) => {
    if(newValue){
      localStorage.removeItem('clientSelected');
      localStorage.setItem('clientSelected',  JSON.stringify(newValue));
      setExtranetUrl(props.user.extranet_url+"&id="+newValue.id)
      setDvUrl("?client_id="+newValue.id)
      props.clientSelected(newValue)
    }
    else{
      localStorage.removeItem('clientSelected');
      setExtranetUrl(props.user.extranet_url)
      setDvUrl("")
      props.clientSelected({})

    }

    
   }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{ top: "52px" }}
    >
      <MenuItem onClick={handleLogOut}>Se déconnecter</MenuItem>
    </Menu>
  );


  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      style={{ top: "52px" }}
    >
      <MenuItem onClick={handleLogOut}>Se déconnecter</MenuItem>
    </Menu>
  );
  const active = "active"

  if (matches) {
    open = false;
  }

  const list = (anchor) => (
    <Box
      sx={{ width: "100%" }}
      role="presentation"
      style={{ textAlign: "center" }}

    >
      {!matches &&
        <>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            id="basic-button"
            aria-controls="basic-menu"
            sx={{ mr: 2 }}

            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMnClick}
            style={{ marginBottom: "12px" }}
          >

            <img src={`${process.env.PUBLIC_URL}/style/GPGlogo.png`} style={{ width: "100px" }} />

          </IconButton>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2 }}
            className="username"
          >
            <Grid container spacing={2} style={{
              marginBottom: "5px",

            }}>
              <Grid item xs={3} style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
                <UserIcon color="#ddd" width="40" height="40" ></UserIcon>
              </Grid>
              <Grid item xs={9} style={{ paddingLeft: "0px" }}>
                <div style={{ textAlign: "left" }}>
                  <div id="username">{username}</div>
                  <span id="mail" style={{ color: "white", fontSize: "12px" }}>{props.user.user.username}</span>
                </div>
              </Grid>
            </Grid>

          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2 }}
            className="username"
          >
            <Grid container spacing={2}>
              <Grid item xs={3} style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
                <StoreIcon color="#ddd" width="40" height="40"></StoreIcon>
              </Grid>
              <Grid item xs={9} style={{ paddingLeft: "0px" }}>
                <FormControl fullWidth >
                  <Autocomplete
                    className="inputForm select userSelect"
                    style={{ marginBottom: "0px", background: "#515151" }}
                    options={clients}
                    defaultValue={ JSON.parse(localStorage.getItem('clientSelected'))}
                    id="tags-standard"
                    onChange={handleSelectedClient}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label=""
                        placeholder="Sélectionner un client"
                        style={{ margin: "4px" }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

            </Grid>
          </Typography>

          <div className="separator"></div>
        </>
      }

      <List>


        <ListItem component={Link} to="/evenements" onClick={handleMnClose} id="0" className={`menu ${activelink === 0 ? "active" : ""}`}>
          <ListItemText primary={"Tous les événements GRC"} />
        </ListItem>
        <ListItem component={Link} to="/evenementgrc" onClick={handleMnClose} id="1" className={`menu ${activelink === 1 ? "active" : ""}`}>
          <ListItemText primary={"Événement GRC"} />
        </ListItem>
        <ListItem component={Link} to="/actionclient" onClick={handleMnClose} id="2" className={`menu ${activelink === 2 ? "active" : ""}`}>
          <ListItemText primary={"Action client"} />
        </ListItem>
        <ListItem component={Link} to="/appuifinance" onClick={handleMnClose} id="3" className={`menu ${activelink === 3 ? "active" : ""}`}>
          <ListItemText primary={"Appui finance"} />
        </ListItem>
        <ListItem component={Link} to="/demandemonument" onClick={handleMnClose} id="4" className={`menu ${activelink === 4 ? "active" : ""}`}>
          <ListItemText primary={"Demande sur monument"} />
        </ListItem>
        <ListItem component='a' onClick={redirectToExtranet} style={{cursor:"pointer"}} className={"menu"}>
          <ListItemText primary={"Extranet"} />
        </ListItem>


        <ListItem component='a' href={'https://dv.gpggranit.com'+dvUrl} onClick={handleMnClose} id={activelink} target="_blank" className={"menu"}>
          <ListItemText primary={"DV Track"} />
        </ListItem>

        <ListItem component='a' href="https://cadeau.gpggranit.com/?token=JAvRKcQtAvusqKd2MjKFUr9tgYpBcLFJz50ThQOEelEbM8zWaItkZmMeAjioZEVH" onClick={handleMnClose} id={activelink} target="_blank" className={"menu"}>
          <ListItemText primary={"Cadeaux Client"} />
        </ListItem>

        <ListItem component='a' href="https://plv.gpggranit.com/?token=Xfoi3q1lock4IBUsC9wpySPOKZzTpoGzBrbGvp5rQTL08pZVzXF1hKleI4LeyXvm" onClick={handleMnClose} id={activelink} target="_blank" className={"menu"}>
          <ListItemText primary={"Dépenses PLV"} />
        </ListItem>

        {trelloUrl != "" ? <ListItem component='a' href={trelloUrl} onClick={handleMnClose} id={activelink} target="_blank" className={"menu"}>
          <ListItemText primary={"Trello"} />
        </ListItem> : ""
        }
        <>
          <div className="separator"></div>
          <ListItem onClick={handleLogOut} style={{ cursor: "pointer" }} className={"menu"}>
            <ListItemText primary={"Se déconnecter"} />
          </ListItem>
        </>


      </List>

    </Box>
  );

  const username = props.user && props.user.user && props.user.user.firstname.charAt(0).toUpperCase() + props.user.user.firstname.slice(1).toLowerCase() + " "
    + props.user.user.lastname.charAt(0).toUpperCase() + props.user.user.lastname.slice(1).toLowerCase();


  return (
    <>
      {!matches && <Box sx={{ flexGrow: 1 }} style={{ width: "100%" }}>
        <AppBar className="navigationBar" position="static">
          <Toolbar>
            {props.isLoggedIn && <>

              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                id="basic-button"
                aria-controls="basic-menu"
                sx={{ mr: 2 }}

                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleMnClick}
              >{!matches &&
                <MenuIcon />}
                {matches &&
                  <img src={`${process.env.PUBLIC_URL}/style/GPGlogo.png`} />
                }
              </IconButton>
              <React.Fragment key={anchorsEl}>

                <Drawer
                  anchor={"left"}
                  open={open}
                  onClose={handleMnClose}
                 
                  className={"navbar"}
                >
                  {list("left")}
                </Drawer>

              </React.Fragment>

            </>
            }
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2 }}
            >
              <img src={`${process.env.PUBLIC_URL}/style/GPGlogo.png`} style={{ width: "72px", marginTop: "8px" }} />
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

          </Toolbar>
        </AppBar>

      </Box>
      }
      {props.isLoggedIn && matches && <div className="navBarDesktop">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          id="basic-button"
          aria-controls="basic-menu"
          sx={{ mr: 2 }}

          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleMnClick}
          style={{ marginBottom: "12px" }}
        >
          {matches &&
            <img src={`${process.env.PUBLIC_URL}/style/GPGlogo.png`} style={{ width: "100px" }} />
          }
        </IconButton>

        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ mr: 2 }}
          className="username"
        >
          <Grid container spacing={2} style={{
            marginBottom: "5px",

          }}>
            <Grid item xs={2} style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <UserIcon color="#ddd" width="40" height="40" ></UserIcon>
            </Grid>
            <Grid item xs={10}>
              <div style={{ textAlign: "left" }}>
                <div id="username">{username}</div>
                <span id="mail" style={{ color: "white", fontSize: "12px" }}>{props.user.user.username}</span>
              </div>
            </Grid>
          </Grid>

        </Typography>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ mr: 2 }}
          className="username"
        >
          <Grid container spacing={2}>
            <Grid item xs={2} style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <StoreIcon color="#ddd" width="40" height="40"></StoreIcon>
            </Grid>
            <Grid item xs={10}>
              <FormControl fullWidth >
                <Autocomplete
                  className="inputForm select userSelect"
                  style={{ marginBottom: "0px", background: "#515151" }}
                  defaultValue={  JSON.parse(localStorage.getItem('clientSelected')) }
                  options={clients}
                  id="tags-standard"
                  onChange={handleSelectedClient}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label=""
                      placeholder="Sélectionner un client"
                      style={{ margin: "4px" }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

          </Grid>
        </Typography>
        {matches &&
          <>
            <div className="separator"></div>
          </>
        }

        {list("left")}</div>}
    </>
  );
}

const mapDataToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    reloadData: () => dispatch(reloadingData()),
    clientSelected : (client) => dispatch(selectedClient(client)),
    getextranetUrl: (id)=>dispatch(getextranetUrl(id))
  };
};

const mapStateToProps = state => ({
  extranetUrl: state.usersReducer.extranet_url
});

export default connect(mapStateToProps, mapDataToProps)(NavBar);